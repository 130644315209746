@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
}

@layer base {
  :root {
    --black: #000;
    --white: #fff;

    --background: #ffffff;
    --foreground: #010b1699;

    --muted: #3c536f;
    --muted-foreground: #000f20;

    --popover: #e8effa;
    --popover-foreground: #3c536f;

    --card: #e8effa;
    --card-foreground: #fffcf0;

    --border: #88a3c1;
    --border-light: #cbd5e0;

    --input: #e8effa;

    --primary: #ffda59;
    --primary-foreground: #6d4800;

    --secondary: #93c5fd99;
    --secondary-foreground: #00254f99;

    --accent: #2581eb26;
    --accent-foreground: #3c536f;

    --destructive: #ef4444ff;
    --destructive-foreground: #f8fafcff;

    --error: #ef4444ff;
    --error-foreground: #f8fafcff;

    --ring: #93c5fd99;

    --radius: 0.5rem;
  }

  .dark {
    --black: #000;
    --white: #fff;

    --background: #060d1c;
    --foreground: #93c5fd99;

    --muted: #4b74a3;
    --muted-foreground: #aac2dd;

    --popover: #091532;
    --popover-foreground: #aac2dd;

    --card: #091531;
    --card-foreground: #1d40b0;

    --border: #17385d;
    --border-light: #3d679647;

    --input: #091532;

    --primary: #ffda59;
    --primary-foreground: #6d4800;

    --secondary: #0080ff;
    --secondary-foreground:var(--background);

    --accent: #2581eb26;
    --accent-foreground: #80b0e399;

    --destructive: #93c5fd99;
    --destructive-foreground: #93c5fd99;

    --error: #ef4444ff;
    --error-foreground: #f8fafcff;

    --ring: #93c5fd99;
  }
}

/* ---------------------------------- */
/* Custom Tailwind Classes
/* ---------------------------------- */
.dark body {
  background-color: #040609 !important;
  background-image: linear-gradient(0deg,#040609,#040609 10%,#050e1d 90%,#050e1d) !important;
}

.container {
  @apply w-full px-0 md:w-[90%] lg:w-[90%] xl:w-[90%] 2xl:w-[100%] max-w-[1280px] m-auto;
}

.container-wide {
  @apply w-full md:w-[90%] lg:w-[90%] xl:w-[90%] 2xl:w-[100%] max-w-[1400px] m-auto;
}

/* ---------------------------------- */
/* Apex: Bar Chart */
/* ---------------------------------- */
.apexcharts-legend-text {
  color: var(--text-muted) !important;
}
.apexcharts-bar-series .apexcharts-datalabel {
  color: var(--bg-secondary) !important;
  fill: var(--bg-secondary) !important;
  font-family: monospace !important;
  font-size: 16px;
  font-weight: 400;
}

/* ---------------------------------- */
/* Apex: Line Chart */
/* ---------------------------------- */
.apexcharts-gridline, .apexcharts-grid-borders line {
  stroke:rgb(96 165 250 / 0.2);
}
.apexcharts-text {
  stroke: rgb(96 165 250 / 0.5);
}
.apexcharts-xaxis-tick {
  stroke: rgb(96 165 250 / 0.5);
}
.apexcharts-data-labels rect {
  fill: #000;
}

.code-block pre {
  border-radius: 8px;
  padding: 18px;
}

@media (prefers-color-scheme: dark) {
  .code-block pre {
    background: #050b17;
  }
}

.code-block pre code {
  white-space: pre-wrap !important;
}

/* Custom Scrollbar */
.custom-scrollbar {
  overflow-x: hidden !important;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0.3rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(254, 208, 0, 1);
  border: 1px solid transparent;
  border-radius: 4px;
}

.apexcharts-tooltip {
  color: #fff !important;
  background: rgba(30, 30, 30, .8) !important;
  border: 1px #333 solid !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  background: rgba(0, 0, 0, .7) !important;
  border-bottom: 1px solid #333 !important;
}

.loader-line {
  width: 100%;
  position: absolute;
  top: 48px;
  background: var(--border-light);
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  width: 40%;
  background-color: var(--primary);
  -webkit-animation: top-loader-animate 1s linear infinite;
  -moz-animation: top-loader-animate 1s linear infinite;
  animation: top-loader-animate 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes top-loader-animate {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

/* Syntax highlighting in JSON viewer */
.json-viewer pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  border-radius: 10px;
  font-size: 1em;
}
.dark .json-viewer pre {
  background: rgb(17, 27, 39);
  color: #fff;
}
.dark .json-viewer .string {
  color: rgb(145, 208, 118);
}
.dark .json-viewer .number {
  color: rgb(230, 211, 122);
}
.dark .json-viewer .boolean {
  color: #1393bd;
}
.dark .json-viewer .null {
  color: rgb(233, 174, 126);
}
.dark .json-viewer .key {
  color: rgb(108, 184, 230);
}

.light .json-viewer pre {
  background: #fff;
  color: rgb(95, 99, 100);
}
.light .json-viewer .string {
  color: rgb(47, 156, 10);
}
.light .json-viewer .number {
  color: rgb(201, 44, 44);
}
.light .json-viewer .boolean {
  color: #ef8f16;
}
.light .json-viewer .null {
  color: rgb(25, 144, 184);;
}
.light .json-viewer .key {
  color: rgb(201, 44, 44);
}